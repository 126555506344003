import "./App.css";
import React, { useEffect, useState } from "react";
import init, { version, hash_check } from "chamelyon-hash";

function App() {
  const q = "";
  const d = 14;
  const y =
    "11cb75cacb41d07623014d67144aa931b977063436238fbe2bb02683e091c2caca839b7c583ff4a6445e184cff41b6dfd4a6394cc1d4885fe2dbd7b164975f7f662e1d52a73babebb79e299d79336c446a423c83284b650051760c789bc504a235b2db1ea5b133c2eb81bd5f9a39fbe687b3691af6b3225ac58ecf4f10da57d5b11e6ced5148191273e8def538de062695aa757f6d300ffcab4c20410272dc42444b5e67d76ceed5cc4ed448bb35613ab251f169e176fb6b2245aa5b411782f25dd777c8cb6097c8ae0f1f4654cbc49c713617adcdac3bd3de3738d9cdf281c031d9f163a33781dfb98669bd7e5872fbffccb4d997f55c4fac853bd0794087ec";
  const h =
    "7b38c07d7f0907ed66d3aacf0aa0d2c526143a1182fd8235492c827d414795a640534ecb5bf1eaca765fff121265656dc2d5449b98cbb082eda7f875768fbfb4dd12f760a92efbbdd044e6a09ec43027d441194e9e60c539a5480d34b94f9c1cb098dacaa977ba47271a0be0c7f8160da3c84c09a4ec21f1ec57de8d5b2fc2f799bc29385e446e28590f81a27d00ca8c3025f4c2246bf096534734885954bea9dcb928a27ea2a06c5687de492968a9c29ce01fe67a6dd1a308507591b607517dbf8a2f35fcaeb314dc7d6c0a5516feca184d8f08092817e7ab06c10c712367eab320fe5a3b887d6687b05afee7fbab13060ca65555cf60a5071eabbd2b99fc9b";
  const a =
    "36b1014c420fa167dac75e8b84ebee663c96ac9dd172d8e6c4739fc86213c4eb80c3712396dbecaf422c5fda2a1d596c698c5f01a970941c05bf2104eec1be236c5c222c6bb3a3ab929bb8a715bdaf75940bd381bb9386acb39e3b64beea7818a9db9737dd29422dc62cd71cf4e9d63eafcae6a7c9b9718d9b186789ec3be10d97dc842cbe1b771bde2192eb4f289e32f43224660c512fdeb5e27e6d49182943aba2c3cb3d33f8f4b601c66fae39df4e9bd9af103da5262a54e61555f149e66fbecde5b86e982004fbe967a1a76bf9c04c9cebce8b4b63396dfa4db22f06887c09dd6826102b52ba0028fe0d391c1dac403c5da48e016a435b06975506c5220";

  const [text, setText] = useState("solo titolo");
  const [hashChecked, setHashChecked] = useState(false);

  useEffect(() => {
    init().then(() => {
      console.log(version());
    });
  }, []);

  useEffect(() => {
    init().then(() => {
      setHashChecked(hash_check(h, a, y, d, q, text));
    });
  }, [text]);

  return (
    <div className="App">
      <header className="App-header">
        <input
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
        />
        <input value={hashChecked} readOnly />
      </header>
    </div>
  );
}

export default App;
